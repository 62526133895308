import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import JobPage from "./components/pages/JobPage";
import OccasionPage from "./components/pages/OccasionPage";
import DienstleistungPage from "./components/pages/DienstleistungPage"
import KontaktPage from "./components/pages/KontaktPage"
import Footer from "./components/molecules/Footer";
import NotFoundPage from "./components/pages/NotFoundPage";
import TeamPage from "./components/pages/TeamPage";
import DabInfoPage from "./components/pages/DabInfoPage";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="occasionen" element={<OccasionPage/>}/>
          <Route path="jobs" element={<JobPage/>}/>
          <Route path="dienstleistungen" element={<DienstleistungPage/>}/>
          <Route path="kontakt" element={<KontaktPage/>}/>
          <Route path="team" element={<TeamPage/>}/>
          <Route path="dab-info" element={<DabInfoPage/>}/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  );
}

export default App;
