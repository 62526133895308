import React from "react";
import { Container, Typography, Button, Card, CardContent, CardActions } from "@mui/material";
import MenuAppBar from "../molecules/MenuAppBar";

const JobPage = () => {
  const handleJobClick = () => {
    window.location.href = "https://www.jobscout24.ch/de/job/automobilfachmann-efz-oder-automobilmechatroniker-efz/116267190/";
  };

  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <MenuAppBar />
      <Typography
        variant="h5"
        align="right"
        gutterBottom
        style={{ color: "white", marginRight: "10px", marginTop: "50px" }}
      >
        Garage Tiefenwaag
      </Typography>
      <Typography
        variant="h5"
        align="right"
        gutterBottom
        style={{ color: "white", marginRight: "10px" }}
      >
        Tel: +41 56 222 75 62
      </Typography>
      <Container maxWidth="md">
        <Typography
          variant="h3"
          gutterBottom
          style={{ color: "#dbbf60", fontSize: "2.5rem" }}
        >
          Stellenangebote
        </Typography>

        <Typography
          variant="body1"
          align="left"
          gutterBottom
          style={{ color: "white", marginTop: "20px", fontSize: "1.4rem" }}
        >
          Hier finden Sie eine Übersicht unserer aktuellen Stellenangebote. Wir freuen uns auf Ihre Bewerbungsunterlagen.
          Bitte senden Sie diese an: <a href="mailto:job@garagetiefenwaag.ch" style={{ color: "#dbbf60", textDecoration: "none" }}>job@garagetiefenwaag.ch</a>. 
        </Typography>

        <Typography
          variant="body1"
          align="left"
          gutterBottom
          style={{ color: "white", fontSize: "1.4rem" }}
        >
          Für Fragen stehen wir Ihnen gerne telefonisch zur Verfügung unter der Nummer
          <span style={{ color: "#dbbf60" }}> +41 56 222 75 62</span>.
        </Typography>

        <Typography
          variant="body1"
          align="left"
          gutterBottom
          style={{ color: "white", marginTop: "20px", fontSize: "1.4rem" }}
        >
          Wir haben laufend interessante Stellenangebote, welche wir gerne{" "}
          <Button onClick={handleJobClick} style={{ color: "#fbc02d", fontSize: "1.4rem"}}>HIER</Button> veröffentlichen.
        </Typography>
      </Container>
      <div style={{ borderTop: "1px solid black", marginTop: "20px" }}></div>
    </div>
  );
};

export default JobPage;
