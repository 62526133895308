import React from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import MenuAppBar from "../molecules/MenuAppBar";

const DabInfoPage = () => {
    const teamMembers = [
        { name: "Roger Meier", imageUrl: require("../Fotos/Roger1.jpg"), education: "Geschäftsinhaber" },
        { name: "Stefan Zbinden", imageUrl: require("../Fotos/Stefan2.jpg"), education: "Geschäftsinhaber" },
        { name: "Simon Vollenweider", imageUrl: require("../Fotos/Simon1.jpg"), education: "Mitarbeiter" },
        { name: "Yannic Zbinden", imageUrl: require("../Fotos/Janic1.jpg"), education: "Lehrling" },
    ];

    return (
        <div style={{ backgroundColor: "black", minHeight: "100vh" }}>
            <MenuAppBar />
            <Typography
                variant="h5"
                align="right"
                gutterBottom
                style={{ color: "white", marginRight: "10px", marginTop: "50px" }}
            >
                Garage Tiefenwaag
            </Typography>
            <Typography
                variant="h5"
                align="right"
                gutterBottom
                style={{ color: "white", marginRight: "10px" }}
            >
                Tel: +41 56 222 75 62
            </Typography>
            <Container maxWidth="md">
                <Typography variant="h3" gutterBottom style={{ color: "#dbbf60", fontSize: "2.5rem" }}>
                    Informationen über DAB
                </Typography>
                <Typography variant="body1" align="left" gutterBottom style={{ color: "white", marginTop: "20px", fontSize: "1.4rem" }}>

                    Wussten Sie, dass viele UKW-Sender ab 2025 abgeschaltet werden? Um weiterhin 
                    Radio in bester Qualität zu hören, empfehlen wir eine Umrüstung auf DAB+. 
                    Details und Möglichkeiten finden Sie in unserem PDF.
                </Typography>
                <Button
                    variant="contained"
                    href={require("../Dateien/DAB_Info.pdf")}
                    target="_blank"
                    style={{ backgroundColor: "#dbbf60", color: "black", marginTop: "20px" }}
                >
                    PDF Herunterladen
                </Button>
            </Container>
            <div style={{ borderTop: "1px solid black", marginTop: "20px" }}></div>
        </div>
    );
};

export default DabInfoPage;
